<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('report_review') }}
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="exportLoading"
          class="d-none d-md-block"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          :loading="exportLoading"
          :disabled="exportLoading"
          fab
          outlined
          icon
          @click="exportExcel"
        >
          <v-icon>{{ icons.mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters @onSendDate="serachReport" />
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-text-field
            v-model.trim="searchtext"
            dense
            :placeholder="$t('search')"
            outlined
            :label="`${$t('pressEnterForSearch')}`"
            @keypress.enter="serachReport(payload.start,payload.end)"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.id`]="{ index }">
          <span v-if="dataTableList.length==index + 1"></span>
          <span v-else>
            {{ index + 1 }}
          </span>
        </template>
        <template v-slot:[`item.course_name`]="{ item }">
          <span
            :class="item.course_name==$t('total')?'font-weight-bold primary--text':''"
          >{{ item.course_name }}</span>
        </template>
        <template v-slot:[`item.rating_all`]="{ item }">
          <span
            :class="item.course_name==$t('total')?'font-weight-bold primary--text':''"
          >{{ item.rating_all }}</span>
        </template>
        <template v-slot:[`item.rating_user`]="{ item }">
          <span
            :class="item.course_name==$t('total')?'font-weight-bold primary--text':''"
          >{{ item.rating_user }}</span>
        </template>
        <template v-slot:[`item.rating_doctor`]="{ item }">
          <span
            :class="item.course_name==$t('total')?'font-weight-bold primary--text':''"
          >{{ item.rating_doctor }}</span>
        </template>
        <template v-slot:[`item.rating_shop`]="{ item }">
          <span
            :class="item.course_name==$t('total')?'font-weight-bold primary--text':''"
          >{{ item.rating_shop }}</span>
        </template>
        <template v-slot:[`item.score_average`]="{ item }">
          <span
            :class="item.course_name==$t('total')?'font-weight-bold primary--text':''"
          >{{ item.score_average }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import useReportReview from './useReviewReport'
import DateFilters from '@/components/basicComponents/DateFilters.vue'

export default {
  components: {
    DateFilters,
  },
  setup() {
    return {
      ...useReportReview(),
    }
  },
}
</script>
